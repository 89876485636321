h1 + * {
  font-family: nyt-cheltenham, georgia, "times new roman", times, serif;
  margin-top: var(--gap, 24px);
}
.hl-wrapper ul.hightlights {
  display: grid;
  gap: var(--gap, 24px);
  grid-template-columns: 1fr;
  margin-bottom: var(--gap, 24px);
}
@media (min-width: 765px) {
  .hl-wrapper ul.hightlights > li:first-of-type {
    grid-row: 1 / -1;
    border-right: 1px solid var(--stroke, #ccc);
    border-top: none;
    padding-right: var(--gap, 24px);
    padding-top: 0;
  }
  .hl-wrapper ul.hightlights {
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto auto auto;
    .story:nth-child(2) {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }
  }
}
@media (min-width: 1024px) {
  .hl-wrapper ul.hightlights {
    grid-template-columns: 4fr 2fr;
  }
}
.support-list {
  --gap: 12px;
  border-top: 1px solid var(--stroke, #ccc);
  display: grid;
  gap: var(--gap, 24px);
  grid-column: 1 / -1;
  grid-template-columns: 1fr;
  padding-top: var(--gap, 24px);
  @media (min-width: 765px) {
    --gap: 32px;
    grid-template-columns: repeat(5, 1fr);
    > .story {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
      position: relative;
    }
    > .story + .story {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
      &::before {
        background: var(--stroke, #ccc);
        content: '';
        height: 90%;
        position: absolute;
        top: 5%;
        transform: translateX(calc((-1 * var(--gap, 24px)) / 2));
        width: 1px;
      }
    }
    .title {
      font-size: 1rem;
    }
  }
}

/* .col {
  position: relative;
}
.col::before {
  background: var(--accent, black);
  content: attr(data-size);
  inset: 0;
  opacity: 0.25;
  position: absolute;
  z-index: 1;
}
.col::after {
  background: var(--accent, black);
  color: white;
  content: attr(data-size);
  display: inline-block;
  inset: 0;
  height: 1.75rem;
  line-height: 1.25;
  margin: 0 auto;
  padding: 3px 0 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 6ch;
} */
