@font-face {
  font-family: 'nyt-cheltenham';
  src: url(https://g1.nyt.com/fonts/family/cheltenham/cheltenham-normal-400.a3ed7afe3eaa0a873f3fbd379f8c491b.woff2);
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'nyt-cheltenham';
  src: url(https://g1.nyt.com/fonts/family/cheltenham/cheltenham-normal-700.530cfb72378419eedb60da7e266ad5f1.woff2);
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'nyt-franklin';
  src: url(https://g1.nyt.com/fonts/family/franklin/franklin-normal-300.a6479a5200f9a6352bdb71589c27c9c3.woff2);
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'nyt-franklin';
  src: url(https://g1.nyt.com/fonts/family/franklin/franklin-normal-500.0f4aea3d462cdb64748629efcbbf36bc.woff2);
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'nyt-franklin';
  src: url(https://g1.nyt.com/fonts/family/franklin/franklin-normal-700.91eaf6b5642463af4091160b4bbfdfcb.woff2);
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'nyt-imperial';
  src: url(https://g1.nyt.com/fonts/family/imperial/imperial-normal-400.6131cd77b6e216c7693ed925f4309ffc.woff2);
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'nyt-imperial';
  src: url(https://g1.nyt.com/fonts/family/imperial/imperial-normal-700.024693f96c8f2c457e4a6a8d02a636b7.woff2);
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

:root {
  --accent: #346eb7;
  --content-base: #121212;
  --content-subtle: #727272;
  --outline-color: #346eb7;
  --stroke: #dfdfdf;

  --gap: 20px;
  --outline-size: max(2px, 0.15em);
}

@media (dynamic-range: high) {
  :root {
    --accent: oklch(55% 0.23 256);
    --outline-color: oklch(55% 0.23 256);
  }
}

:focus-visible {
  --outline-size: max(2px, 0.15em);

  outline: var(--outline-width, var(--outline-size)) var(--outline-style, solid)
    var(--outline-color, currentColor);
  outline-offset: var(--outline-offset, var(--outline-size));
}

body {
  margin: 0;
  font-family: nyt-imperial, georgia, "times new roman", times, serif;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* -------------------- Utilities -------------------- */
.visually-hidden {
  border: 0;
  -webkit-clip: rect(0 0 0 0);
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
