/* Box sizing rules */
*,:after,:before {
  box-sizing: border-box
}

/* Remove default margin */
blockquote,body,dd,dl,figure,h1,h2,h3,h4,p {
  margin: 0
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
:where(ul,ol):where([role=list]) {
  list-style: none;
  margin: 0;
  padding: 0
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth
}

/* Set core body defaults */
body {
  font-family: system-ui,sans-serif;
  font-size: 1.15rem;
  line-height: 1.6;
  min-height: 100vh;
  text-rendering: optimizeSpeed
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  text-decoration-thickness: max(.08em,1px);
  text-underline-offset: .15em
}

/* Make images easier to work with */
img,picture,svg {
  display: block;
  max-width: 100%
}
img[width] {
  height: auto
}
svg {
  fill: currentColor;
  pointer-events: none
}

/* Fix scroll-to content to display on page better */
:target {
  scroll-margin-top: 2rem
}
:focus {
  scroll-margin-bottom: 8vh
}

/* Inherit fonts for inputs and buttons */
button,input,select,textarea {
  font: inherit
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion:reduce) {
  html:focus-within {
    scroll-behavior: auto
  }
  *,:after,:before {
    animation-duration: .01ms!important;
    animation-iteration-count: 1!important;
    scroll-behavior: auto!important;
    transition-duration: .01ms!important
  }
}
